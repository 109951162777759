@font-face {
  font-family: robotoRegular;
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: robotoExtraBold;
  src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: carnacRegular;
  src: url("../fonts/Carnac W03 Regular.ttf");
}

.aboutContent {
  font-size: 25px;
  font-family: robotoRegular, Arial, Helvetica, sans-serif;
}
.navItemStyle {
  color: "#9D9D9D";
  margin-top: 25px;
}
.navItemStyle:hover {
  color: black;
}

html {
  scroll-behavior: smooth;
}

.banner {
  height: 800px;
  background-position: top;
}

.imageContainer {
  margin: 10;
  height: 200;
  cursor: pointer;
}

.readButton {
  background: #d1eb50;
  font-size: 16px;
  font-size: 1rem;
  line-height: 25px;
  position: absolute;
  line-height: 1.5625rem;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  border: none;
  letter-spacing: -0.09px;
  color: #000;
  padding: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  -webkit-appearance: none;
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  min-width: 288px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.h1 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}
h1 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}
h2 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}
h3 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}
h4 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}
h5 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}

h6 {
  font-family: robotoExtraBold, Arial, Helvetica, sans-serif;
}

.d-block {
  object-fit: cover;
}

.logo {
  width: 21%;
  height: 50px;
  margin-left: -7%;
}

.footerMenus {
  color: white;
  width: 150;
  cursor: pointer;
}
.ul {
  list-style: none; /* Remove default bullets */
}

p {
  text-align: justify;
}

.ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #bbe537; /* Change the color */
  font-weight: normal; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  margin-right: 10px; /* Also needed for space (tweak if needed) */
}

.footerMenus:hover {
  color: #bbe537;
}

.facebookIcon {
  background-color: white;
  cursor: pointer;
}

.facebookIcon:hover {
  background-color: #bbe537;
  transition: all;
}

.headerContainer {
  width: 70%;
}

.footerContainer {
  width: 90%;
}

.navItemMargin {
  margin-left: 50px;
}

.navItemStyle {
  font-size: 15px;
  color: black;
  text-transform: uppercase;
}

.headerDirections {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutContainer {
  padding-top: 200px;
  padding-bottom: 100px;
}

.slider {
  height: 600px;
}

@media only screen and (max-width: 400px) {
  .imageContainer {
    /* margin: 30px;
    height: 300px; */
    margin-top: 50px;
  }
  .firstslide {
    height: 216px !important;
  }

  .secondslide {
    height: auto !important;
  }
}

.serviceBox {
  padding: 20px;
  box-shadow: 1px 2px 9px -6px #000000;
  flex: 1;
  display: flex;
  height: 100%;
}

.serviceBox:hover {
  background-color: #bbe537;
  color: white;
  transition: 0.1s ease-in;
}

.serviceBoxText {
  font-size: 15px;
  /* line-height: 2.5px; */
  /* color: gray; */
  margin-right: 10;
  font-family: robotoRegular;
}

@media only screen and (max-width: 1000px) {
  .logo {
    width: 40%;
  }
  .headerContainer {
    width: 95%;
  }
  .navItemMargin {
    margin-left: 30px;
  }

  .slider {
    height: 150px;
  }
  .aboutContent {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1300px) {
  .headerContainer {
    width: 95%;
  }

  .aboutContainer {
    padding-top: 20px;
  }
}

.menuBox {
  -webkit-box-shadow: 0px 7px 13px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 13px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 13px -6px rgba(0, 0, 0, 0.75);
}
